import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import intersect from '@alpinejs/intersect'
Alpine.plugin(collapse)
Alpine.plugin(intersect)
window.Alpine = Alpine
Alpine.start()

import "mburger-webcomponent";
import "mmenu-js";

import GLightbox from "glightbox"; //Lightbox
import 'glightbox/dist/css/glightbox.css';
const lightbox = GLightbox({
  touchNavigation: true,
  selector: "data-lightbox",
  videosWidth: "80vw",
});

import 'image-compare-viewer/dist/image-compare-viewer.min.css'
import ImageCompare from 'image-compare-viewer'
const viewers = document.querySelectorAll(".image-compare");
const staffViewers = document.querySelectorAll(".staff-image-compare");
// For Normal Ones
viewers.forEach((element) => {
  let view = new ImageCompare(element, {
      controlColor: "rgb(243, 115, 49)",
  hoverStart: true
  }).mount();
});
// For Staff Ones
staffViewers.forEach((element) => {
  let view = new ImageCompare(element, {
          controlColor: "rgb(243, 115, 49)",
  hoverStart: true,
  startingPoint: 90
  }).mount();
});


const navbar = document.getElementById('navbar');
let previousScrollPosition = 0;

const handleScroll = () => {
  const currentScrollPosition = window.pageYOffset;

  if (currentScrollPosition > 200) {
    if (currentScrollPosition > previousScrollPosition) {
      navbar.classList.add('-translate-y-full');
    } else {
      navbar.classList.remove('-translate-y-full');
    }
  }

  previousScrollPosition = currentScrollPosition;
};

window.addEventListener('scroll', handleScroll);

